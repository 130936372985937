// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

/*
const firebaseConfig = {
  apiKey: "YOUR_API_KEY",
  authDomain: "YOUR_AUTH_DOMAIN",
  projectId: "YOUR_PROJECT_ID",
  storageBucket: "YOUR_STORAGE_BUCKET",
  messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
  appId: "YOUR_APP_ID"
};
*/
const firebaseConfig = {
    apiKey: "AIzaSyDiGnGnzPc-hsjDE-SsR4yVtRB7wiVSGJw",
    authDomain: "ai-agent-e9896.firebaseapp.com",
    projectId: "ai-agent-e9896",
    storageBucket: "ai-agent-e9896.appspot.com",
    messagingSenderId: "903761419515",
    appId: "1:903761419515:web:c5d4e7e418e6ee9d36645e",
    measurementId: "G-HT57E9CTJR"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const analytics = getAnalytics(app);

export { auth, provider, analytics };
