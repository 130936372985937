// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/HomePage.vue'; // 將 Home 作為首頁組件
import SEOTool from '@/SEOTool.vue'; // 引入新頁面組件
import './../firebaseConfig'; // 引入 Firebase 初始化

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage, // 將 Home 設定為首頁組件
  },
  {
    path: '/seo', // 新頁面的路徑
    name: 'SEOTool',
    component: SEOTool, // 新頁面組件
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
