<!-- App.vue -->
<template>
    <div class="containerx">
         <!-- 登入提示框 -->

        <div v-if="!user" class="login-overlay">
            <div class="login-box">
              <img src="/img/ai-sandy-logo.png" style="border-radius: 50%;" width="100">
              <h2>AI Sandy - SEO Tools</h2>
              <p></p>
              <button class="gsi-material-button" @click="googleSignIn">
                  <div class="gsi-material-button-state"></div>
                  <div class="gsi-material-button-content-wrapper">
                    <div class="gsi-material-button-icon">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </svg>
                    </div>
                    <span class="gsi-material-button-contents">Sign in with Google</span>
                    <span style="display: none;">Sign in with Google</span>
                  </div>
                </button>
                <div class="login-footer">
                  <p class="text-capitalize">AI Sandy 產圖、文章、SEO好工具</p>
                </div>
            </div>
          </div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                <!-- Logo 和產品名稱 -->
                <a class="navbar-brand" href="#">
                <img src="/img/ai-sandy-logo.png" style="border-radius: 50%;margin-right: 5px;" alt="" width="30" height="30" class="d-inline-block align-text-top">
                AI Sandy - SEO Tools
                </a>
                
                <!-- 切換按鈕（小屏幕適配） -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
            </div>
        </nav>
        <!-- 側邊欄切換按鈕 -->
        <button @click="toggleSidebar" :style="toggleButtonStyle" class="btn btn-light slidebar-btn"><i class="fa fa-book" aria-hidden="true"></i></button>
    
        <div v-if="isSidebarVisible" class="sidebar">
        <div v-for="record in sidebarRecords" :key="record.id" class="slidebar-record">
          <img :src="record.photo_url ? record.photo_url : '/img/logo.png'" alt="大頭照" class="slidebar-avatar">
          <div class="slidebar-content" @click="recordClicked(record)">
            <div>{{ record.title }}</div>
            <div class="date-text">{{ record.create_time }}</div>
          </div>
        </div>
      </div>
      
        <div class="main-content" :class="{ 'blur': !user }" style="margin-top: 3rem;">
            <div class="video-block" style="margin-top: 1rem;margin-bottom: 2rem;">
                <div class="input-area-container">
                    <textarea v-model="inputText" class="form-control text-input-area-seo" placeholder="描述文章內容 ex:請產生一篇皮膚疾病與靜脈曲張之間的關係的文章"></textarea>
                    <div class="button-container">
                        <button @click="handleSubmit" :disabled="isSubmitting" class="btn btn-aisandy">
                        <i class="fas fa-paper-plane"></i> 送出
                        </button>
                    </div>
                </div>
                <!-- 在模板中为每个 textarea 添加一个按钮 -->
                <div class="" v-for="(text, index) in responseTexts" :key="`text-${index}`">
                  <ul class="nav nav-tabs" style="margin: 1rem 0rem 0rem 1rem;">
                    <li class="nav-item">
                      <a class="nav-link active" aria-current="page" href="#">{{ '段落 '+(index + 1) }}</a>
                    </li>
                  </ul>  
                    <textarea v-model="responseTexts[index]" class="form-control text-input-area-multi" :placeholder="'段落 ' + (index + 1)" @input="adjustHeight"></textarea>
                    <div v-for="(action, actionIndex) in actions" :key="`action-${actionIndex}`" class="button-container-part">
                        <button @click="handleOptimize(index, action.action, action.text)" :disabled="isSubmitting" class="btn btn-light">
                        {{ action.label }}
                        </button>&nbsp;
                    </div>
                </div>
                    
            </div>
            <div class="text-block" style="height: 100%" ref="textBlock">
                <div class="input-area-container">
                    <textarea v-model="inputImgText" class="form-control text-input-area-seo" placeholder="產圖的描述 ex:人類站立時，小腿看起來皮膚發癢不舒服"></textarea>
                    <div class="button-container">
                        <button @click="handleImageSubmit" :disabled="isImageSubmitting" class="btn btn-aisandy">
                            <i class="fas fa-image"></i> 產圖
                        </button>
                    </div>
                </div>
                <div class="image-container">
                  <div v-for="(url, index) in imageUrls" :key="index" class="image-wrapper">
                    <img :src="url" alt="Generated image" class="generated-image"/>
                    <button class="btn btn-download" @click="downloadImage(url)">
                      <i class="fas fa-download"></i>
                    </button>
                  </div>
                </div>
                <p class="fw-lighter text-center" v-if="imageUrls.length > 0">圖片會過期，請務必自行下載備份</p>
              <div>

                  <!-- 添加合并按钮 -->
                  

                    <!-- 显示合并后的文本和复制按钮 -->
                    <div v-if="mergedText" class="merged-text-container">
                        <textarea readonly class="form-control text-input-area-multi" style="opacity: 0.8;min-height: 500px;" :value="mergedText"></textarea>
                        <div class="merge-button-container">
                        <button @click="mergeTexts" class="btn btn-aisandy"><i class="fas fa-window-restore"></i> 合併左邊段落 </button> &nbsp;
                        <button @click="openPreviewPage" class="btn btn-aisandy"><i class="fas fa-search"></i> 預覽 </button>&nbsp;
                        <button @click="copyMergedText" class="btn btn-aisandy"><i class="far fa-copy"></i> 複製內容</button>&nbsp;
                        <button @click="saveContent" class="btn btn-aisandy"><i class="far fa-save"></i> 儲存</button>
                    </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
   
<style>
.button-container-part {
  display: contents; /* 启用 Flexbox 布局 */
  justify-content: space-between; /* 按钮之间平均分配空间 */
  /* 根据需要添加其他样式，比如间距等 */
}

.text-area-with-buttons {
  margin-bottom: 20px; /* 为每组 textarea 和按钮组添加一些底部外边距 */
}
.merge-button-container{
    margin: 1rem;
}
.toolbar {
  margin-top: 20px;
  text-align: center;
}
.image-container {
  display: flex;
  flex-wrap: wrap; /* 允许内容换行 */
  justify-content: space-between; /* 两张图片之间平均分配额外空间 */
}

.image-wrapper {
  flex-basis: calc(50% - 10px); /* 设置每个图片容器占据一行的50%宽度，减去的10px用于调整间隔 */
  margin-bottom: 20px; /* 根据需要调整图片之间的垂直间距 */
  position: relative; /* 使下载按钮相对于此容器定位 */
}

.generated-image {
  width: 100%; /* 图片填满容器宽度 */
  height: auto; /* 保持图片的纵横比 */
}

.btn-download {
  position: absolute;
  right: 10px; /* 距离容器右边界 */
  bottom: 10px; /* 距离容器底部 */
  background: rgba(255, 255, 255, 0.7); /* 按钮背景色，略微透明 */
  border: none; /* 移除边框 */
  cursor: pointer; /* 鼠标手势为指针 */
  padding: 5px; /* 按钮内边距 */
}



</style>


<script>
import { getAuth, signInWithPopup, GoogleAuthProvider,signInWithCustomToken } from 'firebase/auth';

export default {

  name: 'SEOTool',
  data() {
    return {
      user: null, // 用來儲存用戶資訊
      isSidebarVisible: false, // 新增，用於控制側邊欄顯示
      sidebarRecords: [],
      currentArticleId:0,
      inputText: '', // 綁定到 textarea 的數據
      isSubmitting: false, // 控制按鈕是否禁用的標誌
      isImageSubmitting:false,
      responseTexts: [], // 存储分割后的文本
      mergedText: '  ', // 用于存储合并后的文本
      imageUrls: [], // 新增，用于存储从后端返回的图片 URL
      actions: [
      { label: '舒服順暢', text: '重新優化這段話，閱讀起來可以更舒服、順暢', action: 'optimize' },
      { label: '秒懂口吻', text: '用國小生都能理解的方式來優化這段話', action: 'simplify' },
      { label: '專業術語', text: '用專業人士的口吻、術語來優化這段話', action: 'expert' },
      { label: '厲害標題', text: '用以下內文或標題來重新產出 5 個吸引人的標題', action: 'title' },
      { label: '客制', text: '', action: 'empty' }
    ],
    };
  },
  computed: {
    toggleButtonStyle() {
      // 根據側邊欄是否顯示來決定按鈕的樣式
      if (this.isSidebarVisible) {
        return {
          transform: 'translateX(300px)', // 側邊欄展開時，按鈕向右移動
        };
      } else {
        return {
          transform: 'translateX(0)', // 側邊欄收起時，按鈕回到原位
        };
      }
    },
  },
  mounted() {
    this.checkUserLoginStatus();
  },
  methods: {
    downloadImage(url) {
      const a = document.createElement('a');
      a.href = url;
      a.download = 'image.png'; // 这里可以根据实际情况生成文件名
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    recordClicked(record) {
      this.currentArticleId = record.id;
      this.inputText = record.title;
      console.log(record.content);
      this.responseTexts = record.content.split('\n\n');
            if(this.responseTexts.length>1){
                this.responseTexts[this.responseTexts.length-1] = '';
            }
      //merge all 
      this.mergeTexts();
      this.isSidebarVisible = false;
      //this.imageUrls = record.image_urls;
      //圖片會過期暫時不寫回來
    },
    toggleSidebar() {
        this.isSidebarVisible = !this.isSidebarVisible;
        if (this.isSidebarVisible) {
          this.fetchAllRecords();
        }
      },
    async fetchAllRecords() {
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      try {
        const response = await fetch(`${baseUrl}/api/article/find`);
        if (response.ok) {
          const record_resp = await response.json();
          this.sidebarRecords = record_resp
        } else {
          //this.$toast.error('目前獲取聊天紀錄失敗');
          console.error('獲取All紀錄失敗');
        }
      } catch (error) {
        //this.$toast.error('目前獲取聊天紀錄失敗');
        console.error('獲取All紀錄時發生錯誤', error);
      }
    },  
    async loginWithCustomToken() {
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      const auth = getAuth();
      // 假設 `uid` 是你想要為用戶生成自定義令牌的唯一標識符
      const timestamp = new Date().getTime();
      console.log(timestamp.toString());
      const uid = this.user.uid;
      try {
        const response = await fetch(`${baseUrl}/auth/generateCustomToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        });
        if (!response.ok) throw new Error('Failed to retrieve custom token');
        const { customToken } = await response.json();
        await signInWithCustomToken(auth, customToken);
        console.log('User signed in with custom token');
        // 處理用戶成功登錄後的邏輯...
      } catch (error) {
        console.error('Error logging in with custom token:', error);
      }
    },
    checkUserLoginStatus() {
        const user = localStorage.getItem('user');
        if (user) {
          this.user = JSON.parse(user);
          // 進行其他需要的操作，例如隱藏登入框等
        }
      },
      googleSignIn() {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        signInWithPopup(auth, provider)
          .then((result) => {
            // 登入成功，可以獲取用戶資訊
            this.user = result.user;
            localStorage.setItem('user', JSON.stringify(result.user));
            this.checkUser();
            //塞入預設值
            this.defaultValue();
            // 隱藏登入提示框
            this.fetchLastData(); // 使用 await 確保 fetchLastData 執行完畢
          })
          .catch((error) => {
            // 處理錯誤
            console.error(error);
          });
      },
    async saveContent() {
    this.mergeTexts();//merge content first
    const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
    const apiUrl = this.currentArticleId > 0 ? '/api/article/update' : '/api/article/add'; // 判断是更新还是添加
    const bodyData = {
      title: this.inputText,
      content: this.mergedText,
      image_urls:  this.imageUrls,
      user_id: 1
    };

    // 如果当前文章ID大于0，意味着是更新操作，需要加入article_id
    if (this.currentArticleId > 0) {
      bodyData.article_id = this.currentArticleId;
    }

    try {
      const response = await fetch(`${baseUrl}${apiUrl}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        });

        if (!response.ok) throw new Error('保存失败');

        const data = await response.json();
        // 如果是添加操作，设置返回的新创建的文章ID
        if (this.currentArticleId === 0) {
          this.currentArticleId = data.id;
        }
        if(data.message=='done'){
          alert('儲存完成');
        }
        console.log(data); // 或者在UI上显示消息
      } catch (error) {
        console.error('保存内容时出错:', error);
        // 处理错误，例如显示通知给用户
      }
  },
    openPreviewPage() {
        this.mergeTexts();
        // 创建一个新的窗口
        const previewWindow = window.open('', '_blank');
        if (previewWindow) {
        // 设置新窗口的文档内容
        previewWindow.document.write(`<pre>${this.mergedText}</pre>`);
        previewWindow.document.title = "预览页面";
        } else {
        alert('预览窗口无法打开，请检查浏览器阻止弹出窗口的设置。');
        }
    },
    async handleImageSubmit() {
        if (!this.inputImgText.trim()) return;

        this.isImageSubmitting = true;
        try {
            const response_data = await this.generateImage(this.inputImgText);
            // 这里使用了扩展运算符 (...) 和 map 来追加新的图片 URL
            const newUrls = response_data.data.map(item => item.url);
            this.imageUrls = [...this.imageUrls, ...newUrls]; // 将新 URL 追加到现有数组中
        } catch (error) {
            console.error('生成图片失败:', error);
            alert('圖片產生失敗，請重新再試一次');
        } finally {
            this.isImageSubmitting = false;
        }
    },
    copyMergedText() {
        if (!this.mergedText) return;
        
        // 创建一个 textarea 元素用于复制文本
        const textarea = document.createElement('textarea');
        textarea.value = this.mergedText;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        
        // 可选：显示一个提示信息告知用户文本已复制
        alert('文本已复制到剪贴板');
  },
    mergeTexts() {
        this.mergedText = this.responseTexts.join('\n\n'); // 使用换行符合并文本
    },
    async fetchChatGPTResponse(text) {
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      const response = await fetch(`${baseUrl}/api/chatgpt/fetchChatGPTResponse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          text: text + ' ,文章中會有小標題跟段落',
          key: this.apikey
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch ChatGPT response');
      }

      const data = await response.json();
      return data;
    },
    async generateImage(text) {
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      const response = await fetch(`${baseUrl}/api/chatgpt/generateImage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          text: text ,
          key: this.apikey
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch ChatGPT response');
      }

      const data = await response.json();
      return data;
    },
    async fetchChatGPTResponseOrigin(text) {
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      const response = await fetch(`${baseUrl}/api/chatgpt/fetchChatGPTResponseOrigin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          text: text,
          key: this.apikey
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch ChatGPT response');
      }

      const data = await response.json();
      return data;
    },
    async handleOptimize(index, operation, text) {
        if (!this.responseTexts[index].trim()) return;

        this.isSubmitting = true;
        try {
            // 根据操作类型和文本构造请求数据或调整 API 端点
            //const payload = { text: this.responseTexts[index], operation: operation, style: text };
            const response_data = await this.fetchChatGPTResponseOrigin(text+'\n\n'+this.responseTexts[index]);
            if(this.responseTexts[index]=='\n\n'){
              this.responseTexts[index] = '\n\n' + response_data.choices[0].text.trim();
            }else{
              this.responseTexts[index] = response_data.choices[0].text.trim();
            }
            this.responseTexts = [...this.responseTexts]; // 触发视图更新
            this.mergeTexts();
        } catch (error) {
            console.error('API 请求失败:', error);
        } finally {
            this.isSubmitting = false;
        }
    },
    async handleSubmit() {
        if (!this.inputText.trim()) return;

        this.isSubmitting = true;
        try {
            const response_origin_data = await this.fetchChatGPTResponse(this.inputText);
            this.responseTexts = response_origin_data.choices[0].text.trim().split('\n\n');
            if(this.responseTexts.length>1){
                this.responseTexts[this.responseTexts.length-1] = '\n\n';
            }
            //merge all 
            this.mergeTexts();
            //save
            this.saveContent();
        } catch (error) {
            console.error('API 请求失败:', error);
            this.responseTexts = ['错误: 无法获取数据'];
        } finally {
            this.isSubmitting = false;
        }
    },
    adjustHeight(e) {
        const element = e.target;
        element.style.height = 'auto'; // 先重置高度以确保可以减小
        element.style.height = element.scrollHeight + 'px'; // 然后设置为 scrollHeight
    },
  },
};
</script>
