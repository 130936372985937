import { createApp } from 'vue';
import App from './App.vue'; // 如果 App.vue 是你的入口組件
import Toast from "vue-toastification";
import router from './router'; // 引入路由配置
import './firebaseConfig'; // 引入 Firebase 初始化

// 導入樣式
import "vue-toastification/dist/index.css";

const app = createApp(App);

app.use(Toast, {/* Toast 的全局設定 */});
app.use(router); // 使用路由

app.mount('#app');
