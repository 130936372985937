<template>
    <ul class="submenu">
      <li v-for="menu in menus" :key="menu.id">
        <a :href="menu.link" class="fs-4">{{ menu.title }}</a>
        <transition name="slide-fade">
          <sub-menu v-if="menu.children && menu.children.length" :menus="menu.children"></sub-menu>
        </transition>
      </li>
    </ul>
  </template>
  <style>
    /* 過渡的開始狀態 */
    .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.5s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active 在 <2.1.8 版本中 */ {
    transform: translateY(10px);
    opacity: 0;
    }
  </style>
  <script>
  export default {
    name: 'SubMenu',
    props: ['menus'],
    components: {
      'sub-menu': () => import('./SubMenu.vue') // 遞迴引用自身
    }
  }
  </script>
  